<template>
  <div class="admin-panel bg-primary/25" :class="{ open: showAdmin }">
    <div class="w-full h-full flex flex-col">
      <div
        class="flex-0 p-1 px-3 mb-1 bg-primary text-white grid grid-cols-2 items-center"
      >
        <div class="flex justify-between items-center gap-2">
          <div class="flex items-center">
            <span class="font-bold">
              {{ $t("adminPanel.title") }}
            </span>
            <JoszakiSwitch
              v-model="showAsPublicProfile"
              :label="$t('admin.data.publicProfile')"
            />
          </div>
          <div
            class="relative h-6 w-10 flex items-center justify-center"
            @click="showSettings = !showSettings"
          >
            <IconComponent class="mr-2 cursor-pointer" icon="cog" />
            <div
              v-show="showSettings"
              class="absolute top-6 right-2 bg-blue-100 p-2 w-80 flex flex-col gap-2 z-50 rounded-md shadow-md"
            >
              <JoszakiButton
                type="rating"
                :expanded="true"
                @click="switchBackToAdmin"
              >
                {{ $t("admin.functions.restoreToken") }}
              </JoszakiButton>
              <JoszakiButton type="rating" :expanded="true" @click="copyData">
                {{ $t("admin.functions.copyInfoForDebug") }}
              </JoszakiButton>
            </div>
          </div>
        </div>
        <JoszakiButton
          type="info"
          :inverted="true"
          size="sm"
          @click="toggleAdmin"
        >
          {{ $t("common.close") }}
        </JoszakiButton>
      </div>
      <div
        class="flex-1 p-1 grid gap-2 grid-cols1 md:grid-cols-2 xl:grid-cols-[auto_auto_auto_1fr] overflow-auto max-w-screen-2xl mx-auto"
      >
        <div>
          <AdminPages v-bind="$attrs" />
        </div>
        <div>
          <AdminFunctions v-bind="$attrs" />
        </div>
        <div>
          <AdminContacts v-bind="$attrs" />
        </div>
        <div>
          <AdminData v-bind="$attrs" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, ref } from "@nuxtjs/composition-api";
import { useAdminStore } from "~/stores/admin";
import { useAdminProfessionalStore } from "~/stores/admin/professional";
import { useAdminUserSwitcher } from "~/components/_refactored/admin";

export default {
  components: {
    AdminPages: () => import("~/components/admin/AdminPages.vue"),
    AdminContacts: () => import("~/components/admin/AdminContacts.vue"),
    AdminFunctions: () => import("~/components/admin/AdminFunctions.vue"),
    AdminData: () => import("~/components/_refactored/admin/AdminData.vue"),
  },
  setup() {
    const adminStore = useAdminStore();
    const showAdmin = computed(() => adminStore.panelOpen);

    const showSettings = ref(false);

    function toggleAdmin() {
      adminStore.togglePanel();
    }

    const adminProfessionalStore = useAdminProfessionalStore();
    const showAsPublicProfile = computed({
      get() {
        return !adminProfessionalStore.showAsAdmin;
      },
      set(value) {
        adminProfessionalStore.setShowAsAdmin(!value);
      },
    });

    const { switchBackToAdmin } = useAdminUserSwitcher();

    return {
      showAsPublicProfile,
      showAdmin,
      toggleAdmin,
      switchBackToAdmin,
      showSettings,
    };
  },
  methods: {
    copyData() {
      if (window.isSecureContext) {
        navigator.clipboard.writeText(document.cookie);
        this.$buefy.toast.open({
          duration: 5000,
          message: this.$i18n.t("admin.functions.copyInfoForDebug"),
          type: "is-success",
        });
      }
    },
  },
};
</script>
