<template>
  <JoszakiModal
    :title="$t('creditTopUp.title')"
    :open="isOpen"
    :loading="loading"
    size="md"
    class="z-10"
    @cancel="close"
    @close="close"
    @confirm="topUpCredit"
  >
    <template #body>
      <div class="flex flex-col gap-8">
        <JoszakiRadio v-model="credit" :options="options" />
        <JoszakiInput
          v-if="showOther"
          v-model="formData.value"
          type="number"
          :validation-state="v$.value"
        />
        <JoszakiInput
          v-model="formData.description"
          :label="$t('creditTopUp.description.label')"
          :validation-state="v$.description"
        />
        <!--  -->
      </div>
    </template>
  </JoszakiModal>
</template>

<script>
import { useContext } from "@nuxtjs/composition-api";
import { useVuelidate } from "@vuelidate/core";
import { computed, reactive } from "vue";

import CREDIT_TOP_UP from "./creditTopUp.graphql";
import { useProfessionalStore } from "~/stores/professional";
import { useAdminProfessionalStore } from "~/stores/admin/professional";
import { JOSZAKI_CREDIT_TOP_UP } from "~/plugins/joszaki";

export default {
  setup() {
    try {
      const {
        $validation: { rules },
        i18n,
      } = useContext();

      const formData = reactive({
        value: 5000,
        description: i18n.t("creditTopUp.description.defaultValue"),
      });

      const resetForm = () => {
        formData.value = 5000;
        formData.description = i18n.t("creditTopUp.description.defaultValue");
      };

      const validations = computed(() => {
        const formRules = {
          value: {
            required: rules.required,
            integer: rules.integer,
          },
          description: {
            required: rules.required,
          },
        };
        return formRules;
      });
      const v$ = useVuelidate(validations, formData);
      return {
        v$,
        formData,
        resetForm,
        adminProfessionalStore: useAdminProfessionalStore(),
        professionalStore: useProfessionalStore(),
      };
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
  data() {
    return {
      isOpen: false,
      showOther: false,
      loading: false,
    };
  },
  computed: {
    options() {
      return [
        {
          value: "1000",
          label: "1000",
        },
        {
          value: "5000",
          label: "5000",
        },
        {
          value: "10000",
          label: "10000",
        },
        {
          value: "other",
          label: this.$t("common.other"),
        },
      ];
    },
    professional() {
      return this.professionalStore.person;
    },
    credit: {
      get() {
        return `${this.formData.value}`;
      },
      set(value) {
        if (value === "other") {
          this.formData.value = 0;
          this.showOther = true;
        } else {
          this.formData.value = value;
          this.showOther = false;
        }
      },
    },
  },
  mounted() {
    this.$mitt.on(JOSZAKI_CREDIT_TOP_UP, this.open);
  },
  beforeDestroy() {
    this.$mitt.off(JOSZAKI_CREDIT_TOP_UP, this.open);
  },
  methods: {
    close(isOutsideClick = false) {
      if (!isOutsideClick) {
        this.isOpen = false;
      }
    },
    open() {
      this.isOpen = true;
    },
    async topUpCredit() {
      this.loading = true;
      try {
        const valid = await this.v$.$validate();
        if (!valid) {
          return;
        }
        await this.$mutate(CREDIT_TOP_UP, {
          personId: this.professional.id,
          amount: parseInt(this.formData.value),
          description: this.formData.description,
        });
        this.adminProfessionalStore.loadBalance();
        this.$buefy.toast.open({
          duration: 6000,
          message: this.$t("creditTopUp.successMsg"),
          type: "is-success",
        });
        this.reset();
        this.close();
      } catch (e) {
        console.error(e);
        this.$buefy.toast.open({
          duration: 6000,
          message: e.message,
          type: "is-danger",
        });
      } finally {
        this.loading = false;
      }
    },
    reset() {
      this.resetForm();
      this.showOther = false;
    },
  },
};
</script>
